import React from "react"
import Index from "@pages/mt4-desktop-win.js";

const Mt4DesktopWinJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default Mt4DesktopWinJa
